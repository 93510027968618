import React from 'react'
import {useEffect,useState, useRef} from 'react';
import {returnNext} from './functions.js'
//простой слайдер для показа div блоков
//параметры
//arr=Array - массив со слайдами
//autoplay=Int|false - нужна ли автопрокрутка
//showpoints=true|false - показывать ли точки со слайдером
const preloadPromise=(pic,handler)=> 
{
handler()
const img=new Image()
return new Promise((resolve, reject)=>{
img.src=pic
img.onload=()=>window.setTimeout(()=>resolve(''),50)
img.onerror=()=>resolve('')
})
}

function Slider(props)
{
const [n,setN]=useState(0);
const [stopTimer,setStopTimer]=useState(false)
const [loading,setLoading]=useState(false)
const [currentLoading,setCurrentLoading]=useState(true)
const stopRef=useRef(null)
stopRef.current=stopTimer
const nRef=useRef(null)
const currentLoadingRef=useRef(null)
currentLoadingRef.current=currentLoading
nRef.current=n
const arr=props.arr
const item=arr[nRef.current]
//generate points
const points=<div className="slider_points">{arr.map((item,i)=><div key={i} className={(i===n)?"slider_point slider_point_active":"slider_point"} onClick={()=>{setStopTimer(true);setN(i);}} />)}</div>

//mobile swipe
const [touchStart, setTouchStart]=useState(0);
const [touchEnd, setTouchEnd]=useState(0);
function etouchStart(e) {setTouchStart(e.targetTouches[0].clientX);e.stopPropagation() }
function etouchMove(e) {;setTouchEnd(e.targetTouches[0].clientX);}
function etouchEnd(e)
{
if (props.disableTouch===true) return false;
  //if (Math.abs(touchStart-touchEnd)<10 || touchEnd===0) return false;
let next=(touchStart-touchEnd>50 && touchEnd!==0)?returnNext(false,nRef.current,arr.length):(touchStart-touchEnd<-50 && touchEnd!==0)?returnNext(true,nRef.current,arr.length):false
//console.log(n,next)
setTouchEnd(0)
setTouchStart(0)
  if (next!==false) 
   {
setStopTimer(true) //stop timer when slide by touch
   setN(next)
   }
}
//end swipe
//check load of current slide image
useEffect(() => {
preload(nRef.current,(e)=>setCurrentLoading(e)) // eslint-disable-next-line react-hooks/exhaustive-deps
},[nRef.current])
useEffect(() => {
preload(returnNext(false,nRef.current,arr.length),(e)=>setLoading(e))
let interval;
const goNext=(init=false)=>
{
//	console.log(loading)
if (loading) return;
if (typeof props.autoplay==='undefined' || props.autoplay===null || stopRef.current===true) return;
const next=returnNext(false,nRef.current,arr.length)
preload(returnNext(false,(nRef.current+1),arr.length),(e)=>setLoading(e))
  if (!init && currentLoadingRef.current===false) 
   {
    setN(next)
   }
interval=window.setTimeout(()=>{ goNext()},props.autoplay)
}
goNext(true)
//let interval=window.setTimeout(()=>{ goNext()},props.autoplay)

  return () => {
    clearTimeout(interval);
  };// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
//preload
const preload=(next,handler)=>
{
  if (typeof props.preload!=='undefined' &&props.preload!==null)
  { //console.log(props.preload[next][0])
    preloadPromise(props.preload[next][0],()=>handler(true)).then(()=>
     {
//console.log('loaded',next)
handler(false)
//setN(next)
     })
   }
//console.log('start load',next)
}
return(
<div onTouchStart={(e)=>etouchStart(e)} onTouchEnd={(e)=>etouchEnd(e)} onTouchMove={(e)=>etouchMove(e)} style={{touchAction:'pan-y'}} className={(!currentLoading)?"animate_next_slide":""}>
{item}
{(props.showpoints!==false)?points:''}
</div>
)
}

export default Slider;