import './main.scss';
import {useState, useEffect, useContext, useRef, memo} from 'react';
import {ThemeContext} from './App'
import {returnImgPath, stripTags, useEvent, animateDivScroll,/*isElementVisibleInScrollContainer,returnNext*/} from './functions'
import Slider from "./slider"
import SliderOverflow from "./sliderOverflow"
import BigSlider from './bigslider'
var classNames = require('classnames');
function goApp()
{
  if (/android/.test( navigator.userAgent.toLowerCase())) window.open("https://redirect.appmetrica.yandex.com/serve/316668474296386215?click_id=%7BLOGID%7D&search_term=%7Bkeyword%7D&device_type=%7Bdevice_type%7D&region_name=%7Bregion_name%7D&source_type=%7Bsource_type%7D&phrase_id=%7Bphrase_id%7D&source=%7Bsource%7D&position_type=%7Bposition_type%7D&campaign_id=%7Bcampaign_id%7D")
  else window.open("https://redirect.appmetrica.yandex.com/serve/965186825066976482?click_id=%7BLOGID%7D&search_term=%7Bkeyword%7D&device_type=%7Bdevice_type%7D&region_name=%7Bregion_name%7D&source_type=%7Bsource_type%7D&phrase_id=%7Bphrase_id%7D&source=%7Bsource%7D&position_type=%7Bposition_type%7D&campaign_id=%7Bcampaign_id%7D")
}
function Header()
{
const {theme} = useContext(ThemeContext);
let main_slider=[]
/*
main_slider.push(<div key={1} className="main_slide main_slide_1"  style={{backgroundImage:`url(${returnImgPath(theme,"main_bg.svg")})`}}>
<img src={returnImgPath(theme,"main_car.png")} alt="" className="main_car" />
	</div>)*/
main_slider.push(<div key={'main_slide_'+main_slider.length} className="main_slide main_slide_1"  style={{backgroundImage:`url(${returnImgPath(theme,"main/1_scene.jpg")})`}}>
	</div>)
main_slider.push(<div key={'main_slide_'+main_slider.length} className="main_slide main_slide_2"  style={{backgroundImage:`url(${returnImgPath(theme,"main/2_scene.jpg")})`}}>
	</div>)
main_slider.push(<div key={'main_slide_'+main_slider.length} className="main_slide main_slide_3"  style={{backgroundImage:`url(${returnImgPath(theme,"main/3_scene.jpg")})`}}>
	</div>)
main_slider.push(<div key={'main_slide_'+main_slider.length} className="main_slide main_slide_4"  style={{backgroundImage:`url(${returnImgPath(theme,"main/4_scene.jpg")})`}}>
	</div>)
const bubble_arr=[
{title:"Красивая и тихая беседка в парке",
text:"“В сентябре особенно красиво цветут яблони напротив”",
copy:"Александра",
img:returnImgPath(theme,"main/1_scene_avatar.png")
},
{title:"Protone Impact<br />Fest 2024",
text:"Приходите слушать музыку, лекции и получать протоны за активности!",
copy:"Protone Team",
img:returnImgPath(theme,"main/2_scene_avatar.png")
},
{title:"Станция зарядки электрокаров",
text:"“Удобная станция, 6 зарядок, почти всегда пустует как минимум две”",
copy:"Василий",
img:returnImgPath(theme,"main/3_scene_avatar.png")
},
{title:"Спортивная<br />площадка",
text:"“На площадке есть всё: брусья, тренажеры, баскетбольное поле”",
copy:"Марина",
img:returnImgPath(theme,"main/4_scene_avatar.png")
},
]
let bubble_slider=[]
let preload=[]
preload=[
[returnImgPath(theme,"main/1_scene.jpg")],
[returnImgPath(theme,"main/2_scene.jpg")],
[returnImgPath(theme,"main/3_scene.jpg")],
[returnImgPath(theme,"main/4_scene.jpg")]
	]
//bubble_arr.forEach((el,index) =>{preload.push([el.img])})
  bubble_arr.forEach((el,index) =>
  {
bubble_slider.push(<div key={'bottom_popup_'+index} className="bottom_popup"  style={{backgroundImage:`url(${returnImgPath(theme,"main_popup.svg")})`}}>
		<div className="bottom_popup_title" dangerouslySetInnerHTML={{__html: stripTags(el.title)}}></div>
		<div className="bottom_popup_text">{el.text}</div>
		<div className="bottom_popup_bottom">
			<img src={el.img} alt="" className="bottom_popup_img" />
			<div className="bottom_popup_copy">{el.copy}</div>
		</div>
	</div>
)
			}
)
let  arrSlider=[]
for (var i=0;i<main_slider.length; i++) arrSlider.push(<div key={"arr_slider_"+i}><div className="main_slider">{main_slider[i]}</div><div className="main_buble_slider">{bubble_slider[i]}</div></div>)
return(
<div className="main">
<Slider arr={arrSlider} autoplay={7000} disableTouch={true} preload={preload}  />
	<div className="main_top_title"><span>Protone</span> — твой проводник в мир яркой городской жизни.​</div>
	<img  src={returnImgPath(theme,"qr.png")} alt="" className="main_qr" />
	<div onClick={()=>goApp()} className="main_button button">Скачать приложение</div>
</div>
)
}
	
function AboutOne({img,title,text})
{
return(
<div className="about_one">
	<img src={img} alt="" className="about_img" />
		<div className="about_bubble">
	<div className="about_one_title">{title}</div>
	<div className="about_one_text">{text}</div>
		</div>
</div>
)
}
function About()
{
const {theme} = useContext(ThemeContext);
const arr=[
{img:returnImgPath(theme,"about_1.png"),
title:"Мы — приложение с альтернативной программой лояльности",
text:"Выполняй миссии и получай протоны, которые можно обменять на реальное вознаграждение.​ Ни одно полезное дело не останется незамеченным."
},
{img:returnImgPath(theme,"about_2.png"),
title:"Ощути пульс большого города с Protone",
text:"Проходи образовательные и социально значимые миссии, а также прокачивай себя и свое окружение."
},
{img:returnImgPath(theme,"about_3.png"),
title:"Сообщество",
text:"Мы предлагаем не только широкий спектр миссий в приложении, но и привилегированное участие игроков​ в наших и партнерских мероприятиях - фестивалях, вечеринках и конкурсах. Здесь вы найдете единомышленников и друзей."
},
{img:returnImgPath(theme,"about_4.png"),
title:"Загрузи новый контекст реальности​",
text:"Каждое твое действие имеет значение. С Protone ты создаешь цифровой след новой реальности и делаешь мир лучше.​​"
},
]
const arrSlider=[]
arr.forEach((it,i) =>arrSlider.push(<AboutOne img={it.img} title={it.title} text={it.text} />))
return(
<div className="about" style={{backgroundImage:`url(${returnImgPath(theme,"about_bg.svg")}),var(--about-bg)`}} id="about">
<div className="about_title">О нас</div>
	<div className="about_slider">
<SliderOverflow arr={arrSlider} />
	</div>
</div>)
}
function HowOne({img,title,text,number})
{
return(
<div className="how_one" style={{backgroundImage:`url(${img})`}}>
	<div className="how_one_number">{number}</div>
	<div className="how_one_title">{title}</div>
	<div className="how_one_text">{text}</div>
</div>
)
}
function How()
{
/*
			<div className="how_slider_wrapper">
{arr.map((it,i)=><HowOne img={it.img} title={it.title} text={it.text} number={i+1} />)}
			</div>
*/
const {theme} = useContext(ThemeContext);
const arr=[
{img:returnImgPath(theme,"how_1.png"),
title:"Скачай и авторизуйся в приложении",
text:"Зарегистрируйся, настрой свой профиль и получи приветственные баллы.  А еще можешь создать своего цифрового аватара."
},
{img:returnImgPath(theme,"how_2.png"),
title:"Выбери миссии по душе",
text:"Исследуй разнообразные локации на карте, где мы собрали от образовательных миссий до захватывающих челленджей, которые помогут развить твои навыки и получить новые знания."
},
{img:returnImgPath(theme,"how_3.png"),
title:"Выполняй задания и получай награды",
text:"Проходи миссии один, либо с друзьями, зарабатывай протоны – виртуальные баллы, и присоединяйся к коммьюнити."
},
{img:returnImgPath(theme,"how_4.png"),
title:"Обменивай протоны на реальные призы",
text:"Используй протоны для обмена на ценные награды: сервисы, фирменный мерч и многое другое."
}
]
const arrSlider=[]
arr.forEach((it,i) =>arrSlider.push(<HowOne img={it.img} title={it.title} text={it.text} number={i+1} />))
return(
<div className="how" id="how">
<div className="how_title">Как это работает</div>
	<div className="how_slider">
<SliderOverflow arr={arrSlider} />
	</div>
</div>)
}
function Store()
{

const [bigsize,setBigSize]=useState(false);
const i_resize=(e)=>
{
window.setTimeout(function(){
//setBigSize(window.innerWidth>760?true:false)
setBigSize(window.innerWidth)
},10)
}
//add handler for resize
useEvent('resize', i_resize)
useEvent('load', i_resize)
useEffect(() => { 
i_resize() }// eslint-disable-next-line react-hooks/exhaustive-deps
,[]);
const {theme} = useContext(ThemeContext);
return(
<div className="store"  id="store">
	<div className="store_left"  style={{backgroundImage:`url(${returnImgPath(theme,"store_all_stars.svg")})`}}>
<div className="store_left_title">Система вознаграждения</div>
		<div className="store_left_wrapper">
<img src={returnImgPath(theme,(bigsize>661)?"store_protone.png":"store_protone_mob.png")} alt="Протоны основная игровая валюта" className="store_protone" />
<img src={returnImgPath(theme,(bigsize>661)?"store_atom.png":"store_atom_mob.png")} alt="Атомы дополнительная игровая валюта" className="store_atom" />
		</div>
<div className="store_left_subtitle">В Protone ни одно дело не останется незамеченным.
<br /><br />
<span>Все действия пользователей вознаграждаются виртуальными баллами — протонами и атомами.</span>
</div>
	</div>
	<div className="store_right" style={{backgroundImage:`url(${returnImgPath(theme,(bigsize>760)?"store_bg.jpg":"store_bg_small.jpg")})`}}>
		<div className="store_right_popup">
<div className="store_right_title_wrapper"><img src={returnImgPath(theme,"store_coin.svg")} className="store_right_coin" alt="" /><div className="store_right_title">Магазин наград</div></div>
<div className="store_right_text">Это наш стор, где вы можете обменять заработанные в приложении протоны на реальные награды: от подписок на онлайн-сервисы, лимитированного мерча до современных гаджетов.</div>
<a href="https://store.protone.app" target="_blank" rel="noreferrer" className="store_button button">Перейти к магазину</a>
		</div>
	</div>
</div>
)
}

const ActivitySlider=memo((props)=>
{
const thumbScroll=useRef(null);
const [current,setCurrent]=useState(0)
useEffect(() => {
	
if (thumbScroll.current===null) return
const scrollTo=thumbScroll.current.childNodes[(current===-1)?0:current].offsetLeft-thumbScroll.current.offsetLeft
//const prev=returnNext(true,current,props.aimg.length)
//const prevScrollTo=thumbScroll.current.childNodes[(prev===-1)?0:prev].offsetLeft-thumbScroll.current.offsetLeft
 //  if (!isElementVisibleInScrollContainer(thumbScroll.current.childNodes[current],thumbScroll.current)) 
  if (scrollTo<thumbScroll.current.scrollWidth-thumbScroll.current.offsetWidth || thumbScroll.current.scrollLeft===0  || current===props.aimg.length-1) 
   {
//console.log(current,scrollTo)
animateDivScroll(thumbScroll.current,scrollTo,1000)
   }
  //  console.log(thumbScroll.current.scrollLeft,thumbScroll.current.scrollWidth-thumbScroll.current.offsetWidth)  
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [current]);

  if (typeof props.aimg==='undefined' || props.aimg===null || props.aimg.length===0) return ''
//prepare thumbnails
let thumbs=[]
  for (var i=0; i<props.aimg.length; i++)
   {
let temp=i
const isVideo=props.aimg[i].file.indexOf('vk.com')!==-1 || props.aimg[i].file.indexOf('youtube.com')!==-1 || props.aimg[i].file.indexOf('rutube.ru')!==-1 || props.aimg[i].file.indexOf('.mp4')!==-1
   thumbs.push(<div className={classNames("image",{"image_act":(current===i)?true:false,"image_video":isVideo})} onClick={(e)=>{(e).stopPropagation();setCurrent(temp)}} key={"thumb_"+i} style={{backgroundImage:`url(${props.aimg[i].preview})`}}></div>)
   }
/*
const scroller=(e)=>
{
let scrollTo;
//e.preventDefault()
  if (e.deltaY>=-15 && e.deltaY<=15) scrollTo=thumbScroll.current.scrollLeft+(e.deltaY*40);
  else scrollTo=thumbScroll.current.scrollLeft+(e.deltaY*5);
thumbScroll.current.scrollTo({
  left:scrollTo,
  behavior: "auto"
});
}
*/


return(
<div className="acitivity_slider_wrapper">
<div className="slider_container" ><BigSlider current={current} setcur={(e)=>{setCurrent(e)}} close={props.close} arr={props.aimg} arrows={true} swipe={true} click="once" /></div>
<div className="images" ref={thumbScroll} /*onWheel={(e)=>{scroller(e);}}*/>{thumbs}</div>
</div>
)
}, (prevProps, nextProps) => prevProps.current===nextProps.current)

function ActivityButton({text,set,selected,id})
{
return(
<div className={classNames("activity_button",{"activity_button_act":(id===selected)})} onClick={set}>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 278 72" fill="none" preserveAspectRatio="none" className="activity_button_svg" >
<path d="M2.69231 21.9615C-1.56172 11.7519 5.93954 0.5 17 0.5H244.333C250.591 0.5 256.234 4.26243 258.641 10.0385L275.308 50.0385C279.562 60.2481 272.06 71.5 261 71.5H33.6667C27.4093 71.5 21.7657 67.7376 19.359 61.9615L2.69231 21.9615Z" fill="var(--Surface-surface-primary)" stroke="var(--Stroke-Clickable)"/>
<defs>
<linearGradient id="paint0_linear_602_23710" x1="324.14" y1="-30.1622" x2="312.216" y2="124.502" gradientUnits="userSpaceOnUse">
<stop stopColor="#ABABAB"/>
<stop offset="1" stopColor="#ABABAB" stopOpacity="0.05"/>
</linearGradient>
</defs>
</svg>
<li />{text}
</div>
)
}
function Activity()
{
const [selected,setSelected]=useState(0)
const allSlider=[
{title:'Protone Impact Fest 2024'
,text:'Protone Fest - ежегодное событие, объединяющее людей для обмена идеями, знаниями и творчеством. Он создан для тех, кто стремится к новым открытиям, хочет быть в курсе современных технологий и наслаждаться живой музыкой.<br /><br />Фестиваль предлагает уникальные возможности для участия в интерактивных квестах, прослушивания лекций и создания совместных арт-объектов. Это не просто event, а пространство для развития и взаимодействия, где каждый может найти что-то интересное для себя и внести свой вклад в будущее'
,slider:[
{"file": "./i/activity/2024/1.jpg","preview": "./i/activity/2024/1.jpg"},
{"file": "./i/activity/2024/2.jpg","preview": "./i/activity/2024/2.jpg"},
{"file": "./i/activity/2024/3.jpg","preview": "./i/activity/2024/3.jpg"},
{"file": "./i/activity/2024/4.jpg","preview": "./i/activity/2024/4.jpg"},
{"file": "./i/activity/2024/5.jpg","preview": "./i/activity/2024/5.jpg"},
{"file": "./i/activity/2024/6.jpg","preview": "./i/activity/2024/6.jpg"},
{"file": "./i/activity/2024/7.jpg","preview": "./i/activity/2024/7.jpg"},
{"file": "./i/activity/2024/8.jpg","preview": "./i/activity/2024/8.jpg"},
{"file": "./i/activity/2024/9.jpg","preview": "./i/activity/2024/9.jpg"},
{"file": "https://rutube.ru/play/embed/5609bdbfac3ee0fe8155566b6a283ba9","preview": "./i/activity/2024/10v.jpg"},
{"file": "https://rutube.ru/play/embed/d17a3012aa3c9863b50162dda36daad9","preview": "./i/activity/2024/11v.jpg"},
	]
},
{title:'Protone Impact Fest 2023'
,text:'2Protone Fest - ежегодное событие, объединяющее людей для обмена идеями, знаниями и творчеством. Он создан для тех, кто стремится к новым открытиям, хочет быть в курсе современных технологий и наслаждаться живой музыкой.<br /><br />Фестиваль предлагает уникальные возможности для участия в интерактивных квестах, прослушивания лекций и создания совместных арт-объектов. Это не просто event, а пространство для развития и взаимодействия, где каждый может найти что-то интересное для себя и внести свой вклад в будущее'
,slider:[
{"file": "./i/temp/temp_slider.jpg","preview": "./i/temp/temp_slider.jpg"},
{"file": "./i/temp/temp_slider.jpg","preview": "./i/temp/temp_slider.jpg"},
{"file": "./i/temp/temp_video.mp4","preview": "./i/temp/temp_slider.jpg"},
	]
},
{title:'Grounded Festival 2024'
,text:'3Protone Fest - ежегодное событие, объединяющее людей для обмена идеями, знаниями и творчеством. Он создан для тех, кто стремится к новым открытиям, хочет быть в курсе современных технологий и наслаждаться живой музыкой.<br /><br />Фестиваль предлагает уникальные возможности для участия в интерактивных квестах, прослушивания лекций и создания совместных арт-объектов. Это не просто event, а пространство для развития и взаимодействия, где каждый может найти что-то интересное для себя и внести свой вклад в будущее'
,slider:[
{"file": "./i/temp/temp_slider.jpg","preview": "./i/temp/temp_slider.jpg"},
{"file": "./i/temp/temp_video.mp4","preview": "./i/temp/temp_slider.jpg"},
	]
},
]
return(
<div className="activity" id="activity">
		<div className="activity_left">
<ActivitySlider aimg={allSlider[selected].slider} key={selected} />
		</div>
		<div className="activity_right">
<div className="activity_title">Активность</div>
<div className="activity_text"  dangerouslySetInnerHTML={{__html: stripTags(allSlider[selected].text)}}></div>
		</div>
	<div className="activity_button_wrapper">
{allSlider.map((item,i)=><ActivityButton text={item.title} key={i} set={()=>setSelected(i)} id={i} selected={selected} />)}
	</div>
</div>
)
}
function PartnerBlock({title,topText,bottomText,imgTop,imgLeft,cls})
{
const [open,setOpen]=useState(false)
return(
	<div className={classNames("partners_block",cls)}> 
		<div className="partners_block_title">{title}</div>
			<div className={classNames("partners_block_hide_mobile",{"partners_block_bottom_text_show":open})}>
		<div className="partners_block_top_text">{topText}</div>
		<div className={"partners_block_bottom_text"}>
<div className="partners_block_bottom_title">Что мы сделали:</div>
<div dangerouslySetInnerHTML={{__html: "<ul>"+bottomText+"</ul>"}}></div>
		</div>
			</div>
		<div className="partners_block_button" onClick={()=>setOpen(!open)}>{open?"Свернуть":"Подробнее"}</div>
		<img src={imgTop} alt="" className="partners_block_top_img" />
		<div className="partners_block_left_img" style={{backgroundImage:`url(${imgLeft}),var(--radial-partners-bg)`}}></div>
	</div>	
)
}
function Partners()
{
const {theme} = useContext(ThemeContext);
const arr=[
{
title:"День города Казани",
topText:"Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях.",
bottomText:"<li>Запустили миссию с определением геолокации и распознаванием птичьего пения<li />Собрали данные с более 1000 пользоателей о ореоле гнездения соловьев<li />Передали всю информацию в центр орнитологов в Парке Горького",
imgTop:returnImgPath(theme,"partner_left_1.png"),
imgLeft:returnImgPath(theme,"partner_1_big.png"),
cls:"",
},
{
title:"Cоловей мой соловей",
topText:"Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях.",
bottomText:"<li />Запустили миссию с определением геолокации и распознаванием птичьего пения<li />Собрали данные с более 1000 пользоателей о ореоле гнездения соловьев<li />Передали всю информацию в центр орнитологов в Парке Горького",
imgTop:returnImgPath(theme,"partner_right_1.png"),
imgLeft:returnImgPath(theme,"partner_2_big.png"),
cls:"",
}
]
return(
				<div className="partners" id="partners">
<div className="partners_left">
	<div className="partners_top">
		<div className="partners_title">Партнерство</div>
		<div className="partners_text">Protone — это не просто  приложение с реальными наградами, а целое сообщество тех, кто неравнодушен к своему будущему. Мы стремимся собрать вокруг себя людей, которых стимулирует развитие и достижение целей. </div>
	</div>
	<PartnerBlock  title={arr[0].title} topText={arr[0].topText} bottomText={arr[0].bottomText} imgTop={arr[0].imgTop} imgLeft={arr[0].imgLeft} cls={arr[0].cls} />
</div>
<div className="partners_right">
	<PartnerBlock  title={arr[1].title} topText={arr[1].topText} bottomText={arr[1].bottomText} imgTop={arr[1].imgTop} imgLeft={arr[1].imgLeft} cls={arr[1].cls} />
	<div className="partners_block_end_text">
Присоединяясь к нам, вы поддерживаете будущее, где технологии и мотивация идут рука об руку, создавая позитивные изменения. 
<br /><br />Будьте среди новаторов, формирующих мир завтрашнего дня вместе с Protone!
	</div>
</div>
				</div>
)
}
function ProjectsOne({img,imgLogo,title,text,number})
{
return(
<div className="projects_one">
	<img src={imgLogo} alt="" className="projects_one_logo" />
	<img src={img} alt="" className="projects_one_img" />
	<div className="projects_one_title">{title}</div>
	<div className="projects_one_text">{text}</div>
</div>
)
}
function Projects()
{
const {theme} = useContext(ThemeContext);
const arr=[
{img:returnImgPath(theme,"project_3.png"),
imgLogo:returnImgPath(theme,"project_1_small.png"),
title:"Кино: от немого до наших дней ",
text:"Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях. "
},
{img:returnImgPath(theme,"project_3.png"),
imgLogo:returnImgPath(theme,"project_2_small.png"),
title:"Лидерство, как стиль жизни",
text:"Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях. "
},
{img:returnImgPath(theme,"project_3.png"),
imgLogo:returnImgPath(theme,"project_3_small.png"),
title:"Рециклинг",
text:"Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях. "
},
{img:returnImgPath(theme,"project_3.png"),
imgLogo:returnImgPath(theme,"project_4_small.png"),
title:"Добрые дела и ВКУСВИЛЛ",
text:"Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях. "
}
]
const arrSlider=[]
arr.forEach((it,i) =>arrSlider.push(<ProjectsOne img={it.img} imgLogo={it.imgLogo} title={it.title} text={it.text} />))
return(
<div className="projects" id="projects">
<div className="projects_title">Проекты</div>
<div className="projects_hr"></div>
	<div className="projects_slider">
<SliderOverflow arr={arrSlider} afterPoints={<div className="projects_hr_desktop"></div>} />
	</div>
</div>)
}
function OurMap()
{
const {theme} = useContext(ThemeContext);
return(
<div className="map" id="map">
	<div className="map_title">Импакт Карта</div>
	<div className="map_wrapper">
		<div className="map_left" style={{backgroundImage:`url(${returnImgPath(theme,"map_left.png")})`}}>
		</div>
		<div className="map_right">
			<div className="map_right_wrapper">
			<img src={returnImgPath(theme,"map_right.png")} alt="" className="map_right_img" />
			<div className="map_right_title_mobile">Импакт Карта</div>
			<div className="map_right_text">
Protone помогает пользователям интегрировать полезные привычки в их повседневную жизнь
<br /><br />
Наша карта постоянно расширяется! Регулярно появляются новые партнерские локации.
			</div>
<a href="https://map.protone.app" target="_blank" rel="noreferrer" className="map_button button">Перейти к карте</a>
			</div>
		</div>
	</div>
</div>	
)
}
function Main()
{
useEffect(() => {
  if (window.location.hash!=='') 
{
//исправляет баг некоторых браузеров по работе хеша и реакта
	window.setTimeout(()=>{
const cur=window.location.hash
window.location.hash=''
window.location.hash=cur
  },30)
}	
   } // eslint-disable-next-line react-hooks/exhaustive-deps
,[]);
return (
<div>
<Header />
<div name="about" aria-label="about"  aria-hidden="true" />
<About />
<div name="how" aria-label="how"  aria-hidden="true" />
<How />
<div name="store" aria-label="store"  aria-hidden="true" />
<Store />
<div name="activity" aria-label="activity"  aria-hidden="true" />
<Activity />
<div name="partners" aria-label="partners"  aria-hidden="true" />
<Partners />
<div name="projects" aria-label="projects"  aria-hidden="true" />
<Projects />
<div name="map" aria-label="map"  aria-hidden="true" />
<OurMap />
</div>
	)
}

export default Main;
